import * as React from "react";
import Layout from "../components/layout/Layout";
import { Trans } from "gatsby-plugin-react-i18next";

const NotFound = () => {
  return (
    <Layout>
        <section className="py-20 pt-24 md:mx-24 mx-2 sm:mx-10 px-3 mb-8">
            <h1><Trans i18nKey="404MainTitle" /></h1>
            <p><Trans i18nKey="404Content" /></p>
        </section>
    </Layout>
  )
}

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;